import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { useAuth0 } from '@auth0/auth0-react';

const theme = createTheme({
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#1B1820',
          },
        },
      },
    },
  });
const IOdata: React.FC = () => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {/* <img
                  alt="iOData-logo"
                  width="50"
                  loading="lazy"
                  className="logo"
                  src="/logo.png"
                ></img> */}
          </Typography>
          {isAuthenticated ? (
            <Button color="inherit" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
              Logout
            </Button>
          ) : (
            <Button color="inherit" onClick={() => loginWithRedirect()}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Container>
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          backgroundImage: 'url(/comingsoon.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}>
           
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default IOdata;