import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ImageMapper from "react-img-mapper";
import Stack from "@mui/material/Stack";
import SendIcon from "@mui/icons-material/Send";
import PostAddIcon from "@mui/icons-material/PostAdd";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import Projects from "./Projects";
import Doc from "../../images/doc1.png";

const MAP = {
  name: "projectMap",
  areas: [
    {
      name: "Area1",
      shape: "rect",
      coords: [149, 108, 83, 16],
      href: "/#area1",
      fillColor: "#eab54d4d",
      strokeColor: "black",
    },
    {
      name: "Area2",
      shape: "rect",
      coords: [153, 17, 315, 112],
      href: "/#area2",
      fillColor: "#eab54d4d",
      strokeColor: "black",
    },
    {
      name: "Area3",
      shape: "rect",
      coords: [325, 12, 425, 114],
      href: "/#area3",
      fillColor: "#eab54d4d",
      strokeColor: "black",
    },
  ],
};

export default function Locations() {
  const navigate = useNavigate();
  const [openProject, handleProject] = useState<boolean>(false);
  const handleAreaClick = (area: any) => {
    if (area.href) {
      window.location.href = area.href;
    }
  };

  const getProjects = () => (
    <Projects openProject={openProject} handleProject={handleProject} />
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          <Button variant="contained" endIcon={<PostAddIcon />} onClick={() => navigate("add")}>
            Add Document
          </Button>

          <Button variant="contained" endIcon={<SendIcon />}  onClick={() => navigate("transmittal")}>
            Send Transmittal Set
          </Button>
          <Button variant="contained" endIcon={<SendIcon />}  onClick={() => navigate("submittal")}>
            Send Submittal Set
          </Button>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            variant="contained"
            endIcon={<FolderSpecialIcon />}
            onClick={() => handleProject(true)}
          >
            Projects
          </Button>
        </Stack>
      </Stack>

      <Divider sx={{ m: 2 }}>LOCATIONS</Divider>
      <Box sx={{ display: "flex", m: 3, justifyContent: "center" }}>
        <ImageMapper
          src={Doc}
          map={MAP}
          onClick={(area: any) => handleAreaClick(area)}
          width={1024}
        />
      </Box>
      {getProjects()}
    </>
  );
}
