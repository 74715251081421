import { Box } from "@mui/material";
import Filters from "./Filters";
import ProjectList from "./ProjectList";
import "./index.css";

export default function Projects() {
  return (
    <Box>
        <Filters />
        <ProjectList />
      </Box>
  );
}
