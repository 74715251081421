import ReactFolderTree, { testData } from 'react-folder-tree';
import 'react-folder-tree/dist/style.css';

export default function FolderTree() {
    const onTreeStateChange = (state: any, event: any) => console.log(state, event);
    return (
        <ReactFolderTree
          data={ testData }
          onChange={ onTreeStateChange }
        />
      );
  }
  