import {
  Grid,
  Card,
  ButtonBase,
  CardContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./index.css";

export default function ProjectList() {
  const navigate = useNavigate();
  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2} p={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          {Array(33)
            .fill(0)
            .map((value, index) => (
              <Grid key={value} item>
                <ButtonBase onClick={() => navigate("dashboard")}>
                  <Card sx={{ maxWidth: 200 }}>
                    <img
                      src="bulb.png"
                      className="project-image"
                      alt="project"
                    />
                    {/* <CardMedia
                      sx={{ height: 200 }}
                      image="bulb.png"
                      title="green iguana"
                    /> */}
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {`Project ${index + 1}`}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                      </Typography>
                    </CardContent>
                  </Card>
                </ButtonBase>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
