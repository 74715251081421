import Container from "@mui/material/Container";
import { TextField, Button, Box, Typography } from "@mui/material";
import { Formik, Form, Field, FieldProps } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Header from "../../components/Header";
const initialValues = {
  DocumentCategory: "",
  DocumentType: "",
  Stage: "",
  ProjectLocation: "",
  Level: "",
  ProjectContract: "",
  DocumentOwner: "",
  DocumentOriginator: "",
  Status: "",
  PlannedDate: "",
};

const validationSchema = Yup.object({
  DocumentCategory: Yup.string().required("Required"),
  DocumentType: Yup.string().required("Required"),
  Stage: Yup.string().required("Required"),
  ProjectLocation: Yup.string().required("Required"),
  Level: Yup.string().required("Required"),
  ProjectContract: Yup.string().required("Required"),
  DocumentOwner: Yup.string().required("Required"),
  DocumentOriginator: Yup.string().required("Required"),
  Status: Yup.string().required("Required"),
  PlannedDate: Yup.string().required("Required"),
});

export default function AddDocument() {
  const navigate = useNavigate();

  const onSubmit = (values: typeof initialValues, { resetForm }: any) => {
    console.log("Form data", values);
    resetForm();
  };
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Box sx={{ maxWidth: "600px", margin: "100px auto" }}>
          <Typography
            variant="h4"
            sx={{ margin: "15px  auto", textAlign: "center" }}
          >
            Add Document
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ resetForm }) => (
              <Form>
                {Object.keys(initialValues).map((field, index) => (
                  <Box key={index} sx={{ marginBottom: "16px" }}>
                    <Field name={field}>
                      {({ field, meta }: FieldProps) => (
                        <TextField
                          {...field}
                          label={field.name.split(/(?=[A-Z])/).join(" ")}
                          fullWidth
                          variant="outlined"
                          size="small"
                          error={meta.touched && !!meta.error}
                          helperText={meta.touched && meta.error}
                        />
                      )}
                    </Field>
                  </Box>
                ))}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "24px",
                  }}
                >
                  <Button type="submit" variant="contained" color="success">
                    Submit
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    onClick={() => resetForm()}
                    style={{ margin: "0 10px" }}
                  >
                    Reset
                  </Button>
                  <Button type="button" variant="outlined" color="error"  onClick={() => navigate("/")}>
                    Back
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  );
}
