import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, IconButton } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import {
  Dashboard,
  Assignment,
  AccountTree,
  Folder,
  TextSnippet,
} from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import LayersIcon from "@mui/icons-material/Layers";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import type { Router, Navigation } from "@toolpad/core";
import { Notifications } from "@mui/icons-material";

const NAVIGATION: Navigation = [
  {
    kind: "header",
    title: "Main",
  },
  {
    segment: "dashboard",
    title: "Dashboard",
    icon: <Dashboard />,
  },
  {
    segment: "projects",
    title: "Projects",
    icon: <AccountTree />,
  },
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Documents",
  },
  {
    segment: "document",
    title: "Document",
    icon: <Assignment />,
  },
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Assets",
  },
  {
    segment: "assets",
    title: "Assets",
    icon: <Folder />,
  },
  {
    kind: "divider",
  },
  {
    kind: "header",
    title: "Super Admin",
  },
  {
    segment: "meta",
    title: "Metadata",
    icon: <TextSnippet />,
    children: [
      {
        segment: "categories",
        title: "Categories",
        icon: <TextSnippet />,
      },
      {
        segment: "types",
        title: "Types",
        icon: <DescriptionIcon />,
      },
      {
        segment: "stage",
        title: "Stage",
        icon: <DescriptionIcon />,
      },
      {
        segment: "locations",
        title: "Locations",
        icon: <DescriptionIcon />,
      },
      {
        segment: "sublocations",
        title: "Sub Locations",
        icon: <DescriptionIcon />,
      },
      {
        segment: "contractors",
        title: "Contractors",
        icon: <DescriptionIcon />,
      },
      {
        segment: "originators",
        title: "Originators",
        icon: <DescriptionIcon />,
      },
      {
        segment: "disciplines",
        title: "Disciplines",
        icon: <DescriptionIcon />,
      },
      {
        segment: "statuses",
        title: "Statuses",
        icon: <DescriptionIcon />,
      },
    ],
  },
  {
    segment: "integrations",
    title: "Integrations",
    icon: <LayersIcon />,
  },
];

const theme = createTheme({
  palette: {
    mode: "light", // or 'dark'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function DemoPageContent({ pathname }: { pathname: string }) {
  return (
    <Box
      sx={{
        py: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography>Dashboard content for {pathname}</Typography>
    </Box>
  );
}

interface LayoutProps {
  children: React.ReactNode;
  path: string;
}

export default function Layout(props: LayoutProps) {
  console.log("Layout props:", props);
  const { children, path } = props;
  const navigate = useNavigate();

  const router = useMemo<Router>(() => {
    return {
      pathname: path,
      searchParams: new URLSearchParams(),
      navigate: (pathname) => navigate(String(pathname)),
    };
  }, [path]);

  console.log("router:", router);
  return (
    <AppProvider
      navigation={NAVIGATION}
      theme={theme}
      router={router}
      branding={{
        logo: <img src="logo.png" alt="iOData logo" />,
        title: "iOData",
      }}
    >
      <DashboardLayout
        slotProps={{
          toolbarActions: {
            children: (
              <IconButton aria-label="notifications">
                <Notifications />
              </IconButton>
            ),
          },
        }}
      >
        {children}
      </DashboardLayout>
    </AppProvider>
  );
}
