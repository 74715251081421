import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  InputBase,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AutoComplete from "../../components/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
const StickyHeader = styled(AppBar)(() => ({
  top: 0,
  left: 0,
  right: 0,
  position: "sticky",
}));

const StickyFiltersHeader: React.FC = () => {
  const [age, setAge] = useState("10");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  return (
    <StickyHeader color="transparent">
      <Toolbar>
        {/* <Typography variant="h6">Filters</Typography> */}
        <Box
          sx={{ width: "99%", background: "#FFFFFF" }}
          display="flex"
          justifyContent="center"
        >
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <AutoComplete label="Project Search" />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Location</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={age}
              label="Location"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Lead</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={age}
              label="Lead"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Status</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={age}
              label="Status"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Toolbar>
    </StickyHeader>
  );
};

export default StickyFiltersHeader;
