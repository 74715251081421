import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//import { AuthProvider } from "../context/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import Projects from "../pages/Projects";
import Dashboard from "../pages/Dashboard";
import Document from "../pages/Document";
import NotFound from "../pages/404";
import AddDocument from "../pages/AddDocument";
import Transmittal from "../pages/Transmittal";
import Submittal from "../pages/Submittal";
//import Login from "../pages/Login";
import IOdata from "../pages"; 

//   const routes = useRoutes([
//     { path: '/', element: <Projects /> },
//     { path: 'document', element: <Projects /> },
//     { path: 'add', element: <AddDocument /> },
//     { path: 'transmittal', element: <Transmittal /> },
//     { path: 'submittal', element: <Submittal /> },
//     { path: '*', element: <NotFound /> } // This will catch all unmatched routes
//   ]);

//   return routes;
const Routers: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IOdata />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route
          path="/projects"
          element={<ProtectedRoute path="/projects" component={Projects} />}
        />
        <Route path="/dashboard" element={<ProtectedRoute path="/dashboard" component={Dashboard} />}
        />
        <Route
          path="document"
          element={<ProtectedRoute path="document" component={Document} />}
        />
        <Route
          path="document/add"
          element={
            <ProtectedRoute path="document/add" component={AddDocument} />
          }
        />
        <Route
          path="transmittal"
          element={
            <ProtectedRoute path="transmittal" component={Transmittal} />
          }
        />
        <Route
          path="submittal"
          element={<ProtectedRoute path="submittal" component={Submittal} />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routers;
