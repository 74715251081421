import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import FolderTree from "../../components/FolderTree";

interface ProjectProps {
  openProject: boolean;
  handleProject: (val: boolean) => void;
}
export default function Projects(props: ProjectProps) {
  const { openProject, handleProject } = props;
  return (
    <Drawer
      anchor={"right"}
      open={openProject}
      onClose={() => handleProject(false)}
    >
      <Box sx={{ display: "flex", m: 3, justifyContent: "center" }}>
        <FolderTree />
      </Box>
    </Drawer>
  );
}
