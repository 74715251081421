import { Container, Typography, Grid, Paper, Box, CssBaseline } from "@mui/material";
import { styled } from '@mui/material/styles'; 
import LeftMenu from "../../components/LeftMenu";
import SunburstChart from "../../components/Chart/SumBurst";

const data = {
  name: "root",
  children: [
    {
      name: "Branch 1",
      children: [
        { name: "Leaf 1.1", value: 100 },
        { name: "Leaf 1.2", value: 300 },
      ],
    },
    {
      name: "Branch 2",
      children: [
        { name: "Leaf 2.1", value: 200 },
        { name: "Leaf 2.2", value: 150 },
      ],
    },
  ],
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export default function Document() {
  return (
    <>
     <Box sx={{ display: 'flex' }}>
     <CssBaseline />
     <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Item> 
              <SunburstChart data={data} title="Documents" />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item> 
              <SunburstChart data={data} title="Assets" />
            </Item>
          </Grid>
        </Grid>
      </Box>
      
     </Box>
      
      {/* <Container maxWidth={false} sx={{ height: "100vh", my: 2, mt: 12 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Dashboard
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Item>
              asdasd
              <SunburstChart data={data} />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              asdasd
              <SunburstChart data={data} />
            </Item>
          </Grid>
        </Grid>
          </Box>
        
      </Container> */}
    </>
  );
}
