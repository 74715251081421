import React, { useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import * as d3 from "d3";

interface SunburstData {
  name: string;
  children?: SunburstData[];
  value?: number;
}

interface SunburstChartProps {
  data: SunburstData;
  title: string;
}

const SunburstChart: React.FC<SunburstChartProps> = ({ data, title }) => {
  const ref = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const width = 300;
      const radius = width / 6;

      const partition = (data: SunburstData) => {
        const root = d3
          .hierarchy(data)
          .sum((d) => d.value || 0)
          .sort((a, b) => (b.value || 0) - (a.value || 0));
        return d3
          .partition<SunburstData>()
          .size([2 * Math.PI, root.height + 1])(root);
      };

      const root = partition(data);

      root.each((d: any) => (d.current = d));

      const arcVisible = (d: d3.HierarchyRectangularNode<SunburstData>) =>
        d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;

      const labelVisible = (d: d3.HierarchyRectangularNode<SunburstData>) =>
        d.y1 <= 3 && d.y0 >= 1 && (d.x1 - d.x0) * (d.y1 - d.y0) > 0.03;

      const labelTransform = (d: d3.HierarchyRectangularNode<SunburstData>) => {
        const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
        const y = ((d.y0 + d.y1) / 2) * radius;
        return `rotate(${x - 90}) translate(${y},0) rotate(${
          x < 180 ? 0 : 180
        })`;
      };

      const clicked = (
        event: any,
        p: d3.HierarchyRectangularNode<SunburstData>
      ) => {
        parent.datum(p.parent || root);

        root.each((d: any) => {
          d.target = {
            x0:
              Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            x1:
              Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
              2 *
              Math.PI,
            y0: Math.max(0, d.y0 - p.depth),
            y1: Math.max(0, d.y1 - p.depth),
          };
        });

        const t: any = g.transition().duration(750);

        path
          .transition(t)
          .tween("data", (d: any) => {
            const i = d3.interpolate(d.current, d.target);
            return (t: any) => (d.current = i(t));
          })
          .filter(function (d: any) {
            return (
              !!this &&
              (!!(this as SVGElement).getAttribute("fill-opacity") ||
                arcVisible(d.target))
            );
          })
          .attr("fill-opacity", (d: any) =>
            arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
          )
          .attrTween("d", (d: any) => () => arc(d.current) as string);

        label
          .filter(function (d: any) {
            return (
              !!this &&
              (!!(this as SVGElement).getAttribute("fill-opacity") ||
                labelVisible(d.target))
            );
          })
          .transition(t)
          .attr("fill-opacity", (d: any) => +labelVisible(d.target))
          .attrTween("transform", (d: any) => () => labelTransform(d.current));
      };

      const svg = d3
        .select(ref.current)
        .style("width", "300")
        .style("height", "300")
        .style("font", "10px sans-serif");

      const g = svg
        .append("g")
        .attr("transform", `translate(${width / 2},${width / 2})`);

      const arc = d3
        .arc<d3.HierarchyRectangularNode<SunburstData>>()
        .startAngle((d) => d.x0)
        .endAngle((d) => d.x1)
        .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
        .padRadius(radius * 1.5)
        .innerRadius((d) => d.y0 * radius)
        .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

      const path = g
        .append("g")
        .selectAll("path")
        .data(root.descendants().slice(1))
        .join("path")
        .attr("fill", (d: any) => {
          while (d.depth > 1) d = d.parent;
          return d3.interpolateRainbow(d.x0 / 2 / Math.PI);
        })
        .attr("fill-opacity", (d: any) =>
          arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
        )
        .attr("d", (d: any) => arc(d.current) as string);

      path
        .filter((d: any) => d.children)
        .style("cursor", "pointer")
        .on("click", clicked);

      path.append("title").text(
        (d) =>
          `${d
            .ancestors()
            .map((d) => d.data.name)
            .reverse()
            .join("/")}\n${d.value}`
      );

      const label = g
        .append("g")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .style("user-select", "none")
        .selectAll("text")
        .data(root.descendants().slice(1))
        .join("text")
        .attr("dy", "0.35em")
        .attr("fill-opacity", (d: any) => +labelVisible(d.current))
        .attr("transform", (d: any) => labelTransform(d.current))
        .text((d: any) => d.data.name);

      const parent = g
        .append("circle")
        .datum(root)
        .attr("r", radius)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .on("click", clicked);

      return () => {
        svg.selectAll("*").remove();
      };
    }
  }, [data]);

  return (
    <>
      {" "}
      <Typography variant="h5" align="center" gutterBottom>
        {title}
      </Typography>
      <svg ref={ref}></svg>
    </>
  );
};

export default SunburstChart;
