import Container from "@mui/material/Container";
import {
  TextField,
  Button,
  Box,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider
} from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Formik, Form, Field, FieldProps } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Header from "../../components/Header";
const initialValues = {
  TransmittalPurpose: "",
  SendTransmittalTo: "",
};

const validationSchema = Yup.object({
  TransmittalPurpose: Yup.string().required("Required"),
  SendTransmittalTo: Yup.string().required("Required"),
});

export default function Transmittal() {
  const navigate = useNavigate();

  const onSubmit = (values: typeof initialValues, { resetForm }: any) => {
    console.log("Form data", values);
    resetForm();
  };
  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;

    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={`Item ${index + 1}`} />
        </ListItemButton>
      </ListItem>
    );
  }
  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Box sx={{ maxWidth: "600px", margin: "100px auto" }}>
          <Typography
            variant="h4"
            sx={{ margin: "15px  auto", textAlign: "center" }}
          >
            Transmittal
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box
              component="main"
              sx={{ width: { sm: 240 }, flexShrink: { sm: 0 } }}
              aria-label="mailbox folders"
            >
              <Divider sx={{ m: 2 }}>SET</Divider>
              <FixedSizeList
                height={400}
                width={240}
                itemSize={46}
                itemCount={200}
                overscanCount={5}
              >
                {renderRow}
              </FixedSizeList>
            </Box>
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${240}px)` } }}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ resetForm }) => (
                  <Form>
                    {Object.keys(initialValues).map((field, index) => (
                      <Box key={index} sx={{ marginBottom: "16px" }}>
                        <Field name={field}>
                          {({ field, meta }: FieldProps) => (
                            <TextField
                              {...field}
                              label={field.name.split(/(?=[A-Z])/).join(" ")}
                              fullWidth
                              variant="outlined"
                              size="small"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </Box>
                    ))}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "24px",
                      }}
                    >
                      <Button type="submit" variant="contained" color="success">
                        Submit
                      </Button>
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() => resetForm()}
                        style={{ margin: "0 10px" }}
                      >
                        Reset
                      </Button>
                      <Button
                        type="button"
                        variant="outlined"
                        color="error"
                        onClick={() => navigate("/")}
                      >
                        Back
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
}
