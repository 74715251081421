import { FC } from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import Layout from "../layout";

interface ProtectedRouteProps {
  exact?: boolean;
  path: string;
  component: any;
}

const ProtectedRoute: FC<ProtectedRouteProps & RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>;
  }

 return isAuthenticated ? <Layout {...rest}><Component /></Layout> : <Navigate to="/" />;
};

export default ProtectedRoute;
